import { deleteData, fetchData, postData, putData } from "axios/axiosUtils";

export async function checkSubscription(params) {
    const url = `/api/subscriptions/checkSub`;
    return fetchData(url, params);
}

export async function checkOpenCourses(params) {
    const url = `/api/courses/user`;
    return fetchData(url, params);
}

export async function generatePayment(data) {
    const url = `/api/subscriptions/create-checkout-session`;
    return postData(url, data);
}

export async function updateCourseUser(id, data) {
    const url = `/api/subscriptions/${id}`;
    return putData(url, data);
}

export async function addCourseUser(data) {
    const url = `/api/subscriptions`;
    return postData(url, data);
}

export async function deleteCourseUser(id) {
    const url = `/api/subscriptions/${id}`;
    return deleteData(url);
}

export async function batchItemAccess(data) {
    const url = `/api/subscriptions/batch/access`;
    return putData(url, data);
}

