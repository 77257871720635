import { fetchData, postData, putData } from "axios/axiosUtils";

export async function getAllUser(params) {
  const url = `/api/users`;
  return fetchData(url, params);
}

export async function getUserContent(params, id) {
  const url = `/api/users/course/${id}`;
  return fetchData(url, params);
}

export async function getMeetings(params, id) {
  const url = `/api/users/meeting/${id}`;
  return fetchData(url, params);
}

export async function joinMeeting(id) {
  const url = `/api/users/meeting/join/${id}`;
  return postData(url, {});
}

export async function userProfileUpdate(data) {
  const url = `/api/users/update`;
  return putData(url, data);
}

export async function getUserProfile(params) {
  const url = `/api/users/me`;
  return fetchData(url, params);
}

export async function getVideoSproutAccess(params, id) {
  const url = `/api/subscriptions/access/${id}`;
  return fetchData(url, params);
}
