// action - state management
import * as actionTypes from "../actions";

export const initialState = {
  userProfile: {},
  userList: [],
  subsribedUserExportList: [],
  userVideos: [],
  videoPassword: "",
  userPdfs: [],
  userMeeting: [],
  subscribedCourses: [],
  openCourses: [],
  registrationRequiredCourses: [],
  paymentUrl: null,
  loading: false,
  openCourseLoading: true,
  subCheckLoading: true,
  loadingForRegistrationCheck: true,
  loadingContents: false,
  updateError: false,
  getError: false,
  success: false,
  message: null,
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_USER:
      return {
        ...state,
        message: action?.payload?.message || "",
        loading: action?.payload?.loading || false,
        getError: action?.payload?.error || false,
        userList: action?.payload?.data || [],
      };
    case actionTypes.GET_USER_VIDEOS:
      return {
        ...state,
        success: action?.payload?.success || false,
        getError: action?.payload?.error || false,
        message: action?.payload?.message || "",
        loadingContents: action?.payload?.loading || false,
        userVideos: action?.payload?.data || [],
        videoPassword: action?.payload?.videoPassword || [],
      };
    case actionTypes.GET_USER_PDFS:
      return {
        ...state,
        success: action?.payload?.success || false,
        getError: action?.payload?.error || false,
        message: action?.payload?.message || "",
        loadingContents: action?.payload?.loading || false,
        userPdfs: action?.payload?.data || [],
      };
    case actionTypes.GET_USER_MEETINGS:
      return {
        ...state,
        success: action?.payload?.success || false,
        getError: action?.payload?.error || false,
        message: action?.payload?.message || "",
        loadingContents: action?.payload?.loading || false,
        userMeeting: action?.payload?.data || [],
      };
    case actionTypes.GET_USER_MEETING_LINK:
      return {
        ...state,
        success: action?.payload?.success || false,
        getError: action?.payload?.error || false,
        message: action?.payload?.message || "",
        loadingContents: action?.payload?.loading || false,
      };
    case actionTypes.UPDATE_SUBSCRIBED_USER:
      return {
        ...state,
        success: action?.payload?.success || false,
        getError: action?.payload?.error || false,
        message: action?.payload?.message || "",
        loading: action?.payload?.loading || false,
      };
    case actionTypes.ADD_NEW_SUBSCRIBED_USER:
      return {
        ...state,
        success: action?.payload?.success || false,
        getError: action?.payload?.error || false,
        message: action?.payload?.message || "",
        loading: action?.payload?.loading || false,
      };
    case actionTypes.GET_SUBSCRIBED_COURSES:
      return {
        ...state,
        success: action?.payload?.success || false,
        getError: action?.payload?.error || false,
        message: action?.payload?.message || "",
        subCheckLoading: action?.payload?.loading || false,
        subscribedCourses: action?.payload?.data || [],
      };
    case actionTypes.GET_OPEN_COURSES:
      return {
        ...state,
        success: action?.payload?.success || false,
        getError: action?.payload?.error || false,
        message: action?.payload?.message || "",
        openCourseLoading: action?.payload?.loading || false,
        openCourses: action?.payload?.data || [],
      };
    case actionTypes.GET_PAYMENT_URL:
      return {
        ...state,
        success: action?.payload?.success || false,
        getError: action?.payload?.error || false,
        message: action?.payload?.message || "",
        loading: action?.payload?.loading || false,
        paymentUrl: action?.payload?.data || null,
      };
    case actionTypes.REGISTRATION_REQUIRE_CHECK:
      var openCourseIds = state?.openCourses?.map((item) => item) || [];
      var subCourseIds =
        state?.subscribedCourses?.map((item) => item._id) || [];
      var openItems = [];
      openCourseIds.map((element) => {
        if (subCourseIds.indexOf(element._id) === -1 && element?.is_visible) {
          openItems.push(element._id);
        }
      });
      return {
        ...state,
        loadingForRegistrationCheck: false,
        registrationRequiredCourses: openItems,
      };
    case actionTypes.SEND_CERTIFICATE_EMAIL:
      return {
        ...state,
        message: action?.payload?.message || "",
        loading: action?.payload?.loading || false,
        getError: action?.payload?.error || false,
      };
    case actionTypes.UPDATE_USER_PROFILE:
      return {
        ...state,
        success: action?.payload?.success || false,
        updateError: action?.payload?.error || false,
        message: action?.payload?.message || "",
        loading: action?.payload?.loading || false,
      };
    case actionTypes.GET_USER_PROFILE:
      return {
        ...state,
        success: action?.payload?.success || false,
        getError: action?.payload?.error || false,
        userProfile: action?.payload?.data || "",
        message: action?.payload?.message || "",
        loading: action?.payload?.loading || false,
      };
    case actionTypes.EXPORT_SUBSCRIBED_USER_LIST:
      return {
        ...state,
        success: action?.payload?.success || false,
        getError: action?.payload?.error || false,
        subsribedUserExportList: action?.payload?.data || "",
        message: action?.payload?.message || "",
        loading: action?.payload?.loading || false,
      };
    case actionTypes.RESET_USER_STORE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default userReducer;
