// action - state management
import * as actionTypes from "./../actions";

export const initialState = {
  courseList: [],
  courseItem: {},
  loading: false,
  updateError: false,
  getError: false,
  success: false,
  message: null,
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const courseReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_COURSE:
      return {
        ...state,
        success: action?.payload?.success || false,
        message: action?.payload?.message || "",
        loading: action?.payload?.loading || false,
        updateError: action?.payload?.error || false,
      };
    case actionTypes.UPDATE_COURSE:
      return {
        ...state,
        success: action?.payload?.success || false,
        message: action?.payload?.message || "",
        loading: action?.payload?.loading || false,
        updateError: action?.payload?.error || false,
        courseItem: action?.payload?.data || {}
      };
    case actionTypes.GET_ALL_COURSES:
      return {
        ...state,
        message: action?.payload?.message || "",
        loading: action?.payload?.loading || false,
        getError: action?.payload?.error || false,
        courseList: action?.payload?.data || [],
      };
    case actionTypes.GET_COURSE_BY_ID:
      return {
        ...state,
        message: action?.payload?.message || "",
        loading: action?.payload?.loading || false,
        getError: action?.payload?.error || false,
        courseItem: action?.payload?.data || {},
      };
    case actionTypes.DELETE_COURSE:
      return {
        ...state,
        message: action?.payload?.message || "",
        loading: action?.payload?.loading || false,
        getError: action?.payload?.error || false,
      };
    case actionTypes.RESET_COURSE_STORE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default courseReducer;
