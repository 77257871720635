// assets
import {
  IconFingerprint,
  IconNotebook,
  IconCalendarEvent,
  IconVideo,
  IconFile3d,
  IconUser,
  IconReport
} from "@tabler/icons";

// constant
const icons = { IconFingerprint, IconNotebook, IconCalendarEvent, IconVideo, IconFile3d, IconUser, IconReport };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const admin = {
  id: "admin",
  title: "Admin",
  type: "group",
  privateRoute: "true",
  children: [
    {
      id: "adminDashboard",
      title: "Admin Settings",
      type: "item",
      url: "/admin",
      icon: icons.IconFingerprint,
      breadcrumbs: false,
    },
    {
      id: "manageReport",
      title: "Manage Reports",
      type: "item",
      url: "/admin/manage-reports",
      icon: icons.IconReport,
      breadcrumbs: false,
    },
    {
      id: "manageUser",
      title: "Manage User",
      type: "item",
      url: "/admin/manage-user",
      icon: icons.IconUser,
      breadcrumbs: false,
    },
    {
      id: "manageCourse",
      title: "Manage Course",
      type: "item",
      url: "/admin/manage-course",
      icon: icons.IconNotebook,
      breadcrumbs: false,
    },
    {
      id: "manageEvent",
      title: "Manage Event",
      type: "item",
      url: "/admin/manage-event",
      icon: icons.IconCalendarEvent,
      breadcrumbs: false,
    },
    {
      id: "manageVideos",
      title: "Manage Videos",
      type: "item",
      url: "/admin/manage-video",
      icon: icons.IconVideo,
      breadcrumbs: false,
    },
    {
      id: "managePdf",
      title: "Manage PDF",
      type: "item",
      url: "/admin/manage-pdf",
      icon: icons.IconFile3d,
      breadcrumbs: false,
    },
  ],
};

export default admin;
