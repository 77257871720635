// action - state management
import * as actionTypes from "../actions";

export const initialState = {
  videoList: [],
  loading: false,
  updateError: false,
  getError: false,
  success: false,
  message: null,
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const videoReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_VIDEO:
      return {
        ...state,
        success: action?.payload?.success || false,
        message: action?.payload?.message || "",
        loading: action?.payload?.loading || false,
        updateError: action?.payload?.error || false,
      };
    case actionTypes.GET_ALL_VIDEOS:
      return {
        ...state,
        message: action?.payload?.message || "",
        loading: action?.payload?.loading || false,
        getError: action?.payload?.error || false,
        videoList: action?.payload?.videoList || [],
      };
    case actionTypes.DELETE_VIDEO:
      return {
        ...state,
        message: action?.payload?.message || "",
        loading: action?.payload?.loading || false,
        getError: action?.payload?.error || false,
      };
    case actionTypes.RESET_VIDEO_STORE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default videoReducer;
