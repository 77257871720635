import { postFormData } from "axios/fetchFormDataUtils";

export async function uploadFile(data) {
  const url = `/api/uploads`;
  return postFormData(url, data);
}

export async function uploadUserFile(data) {
  const url = `/api/subscriptions/batch/upload`;
  return postFormData(url, data);
}
