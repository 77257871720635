// project imports
import {
  clearLocalStorage,
  getStorageValue,
  setStorageValue,
} from "services/localStorageService";

// action - state management
import * as actionTypes from "./../actions";

export const initialState = {
  userInfo: null,
  token: null,
  loading: false,
  error: null,
  isNewUser: false
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const authReducer = (state = initialState, action) => {
  let user = null;
  let token = null;
  switch (action.type) {
    case actionTypes.CHECK_USER_STATUS:
      user = getStorageValue("userInfo", null);
      token = getStorageValue("token", null);
      return {
        ...state,
        userInfo: user,
        token: token,
      };
    case actionTypes.FETCH_USER_DETAILS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_USER_DETAILS_SUCCESS:
      setStorageValue("userInfo", action.payload.userInfo);
      setStorageValue("token", action.payload.token);
      return {
        ...state,
        loading: false,
        error: null,
        userInfo: action.payload.userInfo,
        token: action.payload.token,
        isNewUser: action.payload.isNewUser
      };
    case actionTypes.FETCH_USER_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actionTypes.CLEAR_USER:
      clearLocalStorage("userInfo");
      clearLocalStorage("token");
      window.location.reload()
      return {
        ...state,
        userInfo: initialState.userInfo,
      };
    default:
      return state;
  }
};

export default authReducer;
