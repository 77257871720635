import admin from "./admin";
import pages from "./pages";
// import utilities from './utilities';
//import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [
    admin,
    pages,
    //utilities
  ],
};

export default menuItems;
