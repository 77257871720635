import { sendCertificateEmail } from "axios/certificate/certificateApi";
import { uploadUserFile } from "axios/uploading/uploadingApi";
import {
  addCourseUser,
  batchItemAccess,
  checkOpenCourses,
  checkSubscription,
  deleteCourseUser,
  generatePayment,
  updateCourseUser,
} from "axios/user/subscriptionApi";
import {
  getAllUser,
  getMeetings,
  getUserContent,
  getUserProfile,
  joinMeeting,
  userProfileUpdate,
} from "axios/user/userApi";
import {
  EXPORT_SUBSCRIBED_USER_LIST,
  GET_ALL_USER,
  GET_OPEN_COURSES,
  GET_PAYMENT_URL,
  GET_SUBSCRIBED_COURSES,
  GET_USER_MEETINGS,
  GET_USER_MEETING_LINK,
  GET_USER_PROFILE,
  GET_USER_VIDEOS,
  SEND_CERTIFICATE_EMAIL,
  UPDATE_SUBSCRIBED_USER,
  UPDATE_USER_PROFILE,
} from "store/actions";

import { ExportToCsv } from "export-to-csv";
import format from "date-fns/format";

export const checkUserSubscription = () => {
  return (dispatch) => {
    dispatch({
      type: GET_SUBSCRIBED_COURSES,
      payload: {
        success: false,
        message: null,
        loading: true,
        error: false,
        subscribedCourses: [],
      },
    });
    checkSubscription({})
      .then((res) => {
        dispatch({
          type: GET_SUBSCRIBED_COURSES,
          payload: {
            success: true,
            message: "Course fetched successfully!",
            loading: false,
            error: false,
            data: res?.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_SUBSCRIBED_COURSES,
          payload: {
            success: false,
            message: err.response?.data?.message || "Something went wrong!",
            loading: false,
            error: true,
          },
        });
      });
  };
};

export const checkOpenSubscription = (params) => {
  return (dispatch) => {
    dispatch({
      type: GET_OPEN_COURSES,
      payload: {
        success: false,
        message: null,
        loading: true,
        error: false,
        openCourses: [],
      },
    });
    checkOpenCourses({ ...params })
      .then((res) => {
        dispatch({
          type: GET_OPEN_COURSES,
          payload: {
            success: true,
            message: "Course fetched successfully!",
            loading: false,
            error: false,
            data: res?.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_OPEN_COURSES,
          payload: {
            success: false,
            message: err.response?.data?.message || "Something went wrong!",
            loading: false,
            error: true,
          },
        });
      });
  };
};

export const getPaymentUrl = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_PAYMENT_URL,
      payload: {
        success: false,
        message: null,
        loading: true,
        error: false,
        paymentUrl: null,
      },
    });
    generatePayment(data)
      .then((res) => {
        dispatch({
          type: GET_PAYMENT_URL,
          payload: {
            success: true,
            message: "Payment generated!",
            loading: false,
            error: false,
            data: res?.data?.message,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_PAYMENT_URL,
          payload: {
            success: false,
            message: err.response?.data?.message || "Something went wrong!",
            loading: false,
            error: true,
          },
        });
      });
  };
};

export const getCourseItems = ({ actionType, id }) => {
  return (dispatch) => {
    dispatch({
      type: actionType,
      payload: {
        success: false,
        message: null,
        loading: true,
        error: false,
        loadingContents: true,
      },
    });
    getUserContent(
      { type: actionType === GET_USER_VIDEOS ? "video" : "pdf" },
      id
    )
      .then((res) => {
        dispatch({
          type: actionType,
          payload: {
            success: true,
            message: "Fetched data!",
            loadingContents: false,
            error: false,
            data: res?.data?.contents,
            videoPassword: res?.data?.video_access_password,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: actionType,
          payload: {
            success: false,
            message: err.response?.data?.message || "Something went wrong!",
            loadingContents: false,
            error: true,
          },
        });
      });
  };
};

export const getCourseMeeting = ({ id }) => {
  return (dispatch) => {
    dispatch({
      type: GET_USER_MEETINGS,
      payload: {
        success: false,
        message: null,
        loading: true,
        error: false,
        loadingContents: true,
      },
    });
    getMeetings(null, id)
      .then((res) => {
        dispatch({
          type: GET_USER_MEETINGS,
          payload: {
            success: true,
            message: "Meeting fetched data!",
            loadingContents: false,
            error: false,
            data: res?.data?.meetings,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_USER_MEETINGS,
          payload: {
            success: false,
            message: err.response?.data?.message || "Something went wrong!",
            loadingContents: false,
            error: true,
          },
        });
      });
  };
};

export const generateMeetingLink = ({ id }) => {
  return (dispatch) => {
    dispatch({
      type: GET_USER_MEETING_LINK,
      payload: {
        success: false,
        message: null,
        loading: true,
        error: false,
        loadingContents: true,
      },
    });
    joinMeeting(id)
      .then((res) => {
        dispatch({
          type: GET_USER_MEETING_LINK,
          payload: {
            success: true,
            message: "Meeting link fetched data!",
            loadingContents: false,
            error: false,
            data: res?.data?.message,
          },
        });
        window.location.replace(res?.data?.message);
      })
      .catch((err) => {
        dispatch({
          type: GET_USER_MEETING_LINK,
          payload: {
            success: false,
            message: err.response?.data?.message || "Something went wrong!",
            loadingContents: false,
            error: true,
          },
        });
      });
  };
};

export const fetchAllUser = ({
  page,
  size,
  courseId = "",
  email = undefined,
  country = undefined,
  start_date = undefined,
  end_date = undefined,
}) => {
  return (dispatch) => {
    dispatch({
      type: GET_ALL_USER,
      payload: {
        success: false,
        message: null,
        loading: true,
        error: false,
        loadingContents: true,
      },
    });
    getAllUser({ page, size, courseId, email, country, start_date, end_date })
      .then((res) => {
        dispatch({
          type: GET_ALL_USER,
          payload: {
            success: true,
            message: "Unable to get users!",
            loadingContents: false,
            error: false,
            data: res?.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ALL_USER,
          payload: {
            success: false,
            message: err.response?.data?.message || "Something went wrong!",
            loadingContents: false,
            error: true,
          },
        });
      });
  };
};

export const giveUserAccess = ({
  userId,
  courseId,
  isVOD = true,
  isPDF = false,
}) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SUBSCRIBED_USER,
      payload: { success: false, message: null, loading: true, error: false },
    });
    addCourseUser({ userId, courseId, video: isVOD, pdf: isPDF })
      .then((res) => {
        dispatch({
          type: UPDATE_SUBSCRIBED_USER,
          payload: {
            success: true,
            message: "User successfully added!",
            loading: false,
            error: false,
            data: res?.data,
          },
        });
        dispatch(fetchAllUser({ page: 1, size: 5 }));
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_SUBSCRIBED_USER,
          payload: {
            success: false,
            message: err.response?.data?.message || "Something went wrong!",
            loading: false,
            error: true,
          },
        });
      });
  };
};

export const updateUserAccess = ({
  userId,
  courseId,
  isVOD = true,
  isPDF = false,
  page = 1,
  size = 10
}) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SUBSCRIBED_USER,
      payload: { success: false, message: null, loading: true, error: false },
    });
    updateCourseUser(userId, { courseId, video: isVOD, pdf: isPDF })
      .then((res) => {
        dispatch({
          type: UPDATE_SUBSCRIBED_USER,
          payload: {
            success: true,
            message: "User access updated!",
            loading: false,
            error: false,
            data: res?.data,
          },
        });
        dispatch(fetchAllUser({ page, size, courseId: courseId }));
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_SUBSCRIBED_USER,
          payload: {
            success: false,
            message: err.response?.data?.message || "Something went wrong!",
            loading: false,
            error: true,
          },
        });
      });
  };
};

export const removeUserAccess = (userId, courseId) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SUBSCRIBED_USER,
      payload: { success: false, message: null, loading: true, error: false },
    });
    deleteCourseUser(userId)
      .then((res) => {
        dispatch({
          type: UPDATE_SUBSCRIBED_USER,
          payload: {
            success: true,
            message: "User access updated!",
            loading: false,
            error: false,
            data: res?.data,
          },
        });
        dispatch(fetchAllUser({ page: 1, size: 10, courseId }));
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_SUBSCRIBED_USER,
          payload: {
            success: false,
            message: err.response?.data?.message || "Something went wrong!",
            loading: false,
            error: true,
          },
        });
      });
  };
};

export const dispatchCertificateEmail = (trxnId) => {
  return (dispatch) => {
    dispatch({
      type: SEND_CERTIFICATE_EMAIL,
      payload: { success: false, message: null, loading: true, error: false },
    });
    sendCertificateEmail(trxnId)
      .then((res) => {
        dispatch({
          type: SEND_CERTIFICATE_EMAIL,
          payload: {
            success: true,
            message: "Email sent successfully!",
            loading: false,
            error: false,
            data: res?.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: SEND_CERTIFICATE_EMAIL,
          payload: {
            success: false,
            message: err.response?.data?.message || "Something went wrong!",
            loading: false,
            error: true,
          },
        });
      });
  };
};

export const batchUploadUser = (body) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SUBSCRIBED_USER,
      payload: { success: false, message: null, loading: true, error: false },
    });
    uploadUserFile(body)
      .then(() => {
        dispatch({
          type: UPDATE_SUBSCRIBED_USER,
          payload: {
            success: true,
            message: "Users migrated successfully!",
            loading: false,
            error: false,
          },
        });
      })
      .catch((err) => {
        err
          .text()
          .then((text) => {
            dispatch({
              type: UPDATE_SUBSCRIBED_USER,
              payload: {
                success: false,
                message: JSON.parse(text)?.message || "Something went wrong!",
                loading: false,
                error: true,
              },
            });
          })
          .catch(() => {
            dispatch({
              type: UPDATE_SUBSCRIBED_USER,
              payload: {
                success: false,
                message: "Something went wrong!",
                loading: false,
                error: true,
              },
            });
          });
      });
  };
};

export const fetchUserProfile = () => {
  return (dispatch) => {
    dispatch({
      type: GET_USER_PROFILE,
      payload: {
        success: false,
        message: null,
        loading: true,
        error: false,
        subscribedCourses: [],
      },
    });
    getUserProfile({})
      .then((res) => {
        dispatch({
          type: GET_USER_PROFILE,
          payload: {
            success: true,
            message: "User fetched successfully!",
            loading: false,
            error: false,
            data: res?.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_USER_PROFILE,
          payload: {
            success: false,
            message: err.response?.data?.message || "Something went wrong!",
            loading: false,
            error: true,
          },
        });
      });
  };
};

export const updateUserProfile = (data) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER_PROFILE,
      payload: { success: false, message: null, loading: true, error: false },
    });
    userProfileUpdate(data)
      .then((res) => {
        dispatch({
          type: UPDATE_USER_PROFILE,
          payload: {
            success: true,
            message: "User profile updated successfully!",
            loading: false,
            error: false,
            data: res?.data,
          },
        });
        dispatch(fetchUserProfile());
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_USER_PROFILE,
          payload: {
            success: false,
            message: err.response?.data?.message || "Something went wrong!",
            loading: false,
            error: true,
          },
        });
      });
  };
};

export const exportSubscribedUserList = ({
  page,
  size,
  courseId,
  courseName,
  email,
  country,
}) => {
  return (dispatch) => {
    dispatch({
      type: EXPORT_SUBSCRIBED_USER_LIST,
      payload: {
        success: false,
        message: null,
        loading: true,
        error: false,
        loadingContents: true,
      },
    });
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: false,
      filename: `${courseName}_Subscribed_User`,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: ["Email", "Name", "Country"],
    };
    const csvExporter = new ExportToCsv(options);
    getAllUser({ page, size, courseId, email, country })
      .then((res) => {
        const data = res?.data?.items.map((item) => ({
          email: item.email,
          name: `${item.firstName} ${item.lastName}`,
          country: item.country,
        }));
        csvExporter.generateCsv(data);
        dispatch({
          type: EXPORT_SUBSCRIBED_USER_LIST,
          payload: {
            success: true,
            message: "Unable to get users!",
            loadingContents: false,
            error: false,
            data: res?.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: EXPORT_SUBSCRIBED_USER_LIST,
          payload: {
            success: false,
            message: err.response?.data?.message || "Something went wrong!",
            loadingContents: false,
            error: true,
          },
        });
      });
  };
};

export const exportAllUserList = ({
  page,
  size,
  courseId = "",
  email = undefined,
  country = undefined,
  start_date = undefined,
  end_date = undefined,
}) => {
  return (dispatch) => {
    dispatch({
      type: EXPORT_SUBSCRIBED_USER_LIST,
      payload: {
        success: false,
        message: null,
        loading: true,
        error: false,
        loadingContents: true,
      },
    });
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: false,
      filename: `users_List`,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: ["Email", "Name", "Country", "Address", "Signup Date"],
    };
    const csvExporter = new ExportToCsv(options);
    getAllUser({ page, size, courseId, email, country, start_date, end_date })
      .then((res) => {
        const data = res?.data?.items.map((item) => ({
          email: item.email,
          name: `${item.firstName} ${item.lastName}`,
          country: item.country,
          address:
            item?.street &&
            `${item.street}, ${item.city}, ${item.state} - ${item.postal}`,
          createdAt: item?.createdAt
            ? format(new Date(item?.createdAt), "dd MMM, yyyy hh:mm aaa")
            : "-",
        }));
        csvExporter.generateCsv(data);
        dispatch({
          type: EXPORT_SUBSCRIBED_USER_LIST,
          payload: {
            success: true,
            message: "Unable to get users!",
            loadingContents: false,
            error: false,
            data: res?.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: EXPORT_SUBSCRIBED_USER_LIST,
          payload: {
            success: false,
            message: err.response?.data?.message || "Something went wrong!",
            loadingContents: false,
            error: true,
          },
        });
      });
  };
};

export const updateBatchUserAccess = ({
  courseId,
  items,
  page = 1,
  size = 10
}) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SUBSCRIBED_USER,
      payload: { success: false, message: null, loading: true, error: false },
    });
    batchItemAccess({items})
      .then((res) => {
        dispatch({
          type: UPDATE_SUBSCRIBED_USER,
          payload: {
            success: true,
            message: "User access updated!",
            loading: false,
            error: false,
            data: res?.data,
          },
        });
        dispatch(fetchAllUser({ page, size, courseId: courseId }));
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_SUBSCRIBED_USER,
          payload: {
            success: false,
            message: err.response?.data?.message || "Something went wrong!",
            loading: false,
            error: true,
          },
        });
      });
  };
};
