import { combineReducers } from "redux";

// reducer import
import customizationReducer from "./customization/customizationReducer";
import authReducer from "./authentication/authReducer";
import courseReducer from "./course/courseReducer";
import eventReducer from "./event/eventReducer";
import videoReducer from "./video/videoReducer";
import pdfReducer from "./pdf/pdfReducer";
import userReducer from "./user/userReducer";
import settingsReducer from "./settings/settingsReducer";
import reportsReducer from "./reports/reportReducer";
import certificateReducer from "./certificates/certificateReducer";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  authorization: authReducer,
  course: courseReducer,
  event: eventReducer,
  video: videoReducer,
  pdf: pdfReducer,
  user: userReducer,
  settings: settingsReducer,
  reports: reportsReducer,
  certificate: certificateReducer
});

export default reducer;
