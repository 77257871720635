import { postData, fetchData } from "axios/axiosUtils";

export async function updateSettingsInfo(data) {
  const url = `/api/settings`;
  return postData(url, data);
}

export async function getSettingsInfo(params) {
  const url = `/api/settings`;
  return fetchData(url, params);
}

export async function updateRegistrationSettings(data) {
  const url = `/api/settings/registration`;
  return postData(url, data);
}