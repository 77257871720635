import { getAxiosInstance } from "./axiosInterceptor.ts";

const axiosInstance = getAxiosInstance();

export async function fetchData(url, params) {
  const response = await axiosInstance.get(url, {
    params
  });
  return response;
}

export async function postData(url, body) {
  const response = await axiosInstance.post(url, {
    ...body
  });
  return response;
}

export async function putData(url, body) {
  const response = await axiosInstance.put(url, {
    ...body
  });
  return response;
}

export async function deleteData(url) {
  const response = await axiosInstance.delete(url);
  return response;
}
