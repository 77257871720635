import { lazy } from "react";

// project imports
import Loadable from "ui-component/Loadable";
import MinimalLayout from "layout/MinimalLayout";

// login option 3 routing
const ForgotPassword = Loadable(
  lazy(() => import("views/pages/authentication/ForgotPassword"))
);
const ResetPassword = Loadable(
  lazy(() => import("views/pages/authentication/ResetPassword"))
);
const AuthLogin3 = Loadable(
  lazy(() => import("views/pages/authentication/authentication3/Login3"))
);
const AuthRegister3 = Loadable(
  lazy(() => import("views/pages/authentication/authentication3/Register3"))
);
const UserActivation = Loadable(
  lazy(() => import("views/pages/user/registration/UserActivation"))
);
const GetCertificate = Loadable(
  lazy(() => import("views/pages/user/certificate/GetCertificate"))
);

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "users/reset/password/:id",
      element: <ResetPassword />,
    },
    {
      path: "/login",
      element: <AuthLogin3 />,
    },
    {
      path: "/register",
      element: <AuthRegister3 />,
    },
    {
      path: "/user/activation/confirm",
      element: <UserActivation />,
    },
    {
      path: "/certificate/:id",
      element: <GetCertificate />,
    },
  ],
};

export default AuthenticationRoutes;
