// action - customization reducer
export const SET_MENU = "@customization/SET_MENU";
export const MENU_TOGGLE = "@customization/MENU_TOGGLE";
export const MENU_OPEN = "@customization/MENU_OPEN";
export const SET_FONT_FAMILY = "@customization/SET_FONT_FAMILY";
export const SET_BORDER_RADIUS = "@customization/SET_BORDER_RADIUS";
export const SET_SNACKBAR = "@customization/SET_SNACKBAR";
export const RESET_SNACKBAR = "@customization/RESET_SNACKBAR";
export const SET_MODAL = "@customization/SET_MODAL";
export const RESET_MODAL = "@customization/RESET_MODAL";

// action - auth reducer
export const CHECK_USER_STATUS = "@authentication/CHECK_USER_STATUS";
export const FETCH_USER_DETAILS_STARTED =
  "@authentication/FETCH_USER_DETAILS_STARTED";
export const FETCH_USER_DETAILS_SUCCESS =
  "@authentication/FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAILED =
  "@authentication/FETCH_USER_DETAILS_FAILED";
export const CLEAR_USER = "@authentication/CLEAR_USER";

// action - course reducer
export const RESET_COURSE_STORE = "@course/RESET_COURSE_STORE";
export const GET_ALL_COURSES = "@course/GET_ALL_COURSES";
export const GET_COURSE_BY_ID = "@course/GET_COURSE_BY_ID";
export const CREATE_COURSE = "@course/CREATE_COURSE";
export const UPDATE_COURSE = "@course/UPDATE_COURSE";
export const DELETE_COURSE = "@course/DELETE_COURSE";

// action - event reducer
export const RESET_EVENT_STORE = "@event/RESET_EVENT_STORE";
export const GET_ALL_EVENTS = "@event/GET_ALL_EVENTS";
export const GET_EVENT_BY_ID = "@event/GET_EVENT_BY_ID";
export const CREATE_EVENT = "@event/CREATE_EVENT";
export const UPDATE_EVENT = "@event/UPDATE_EVENT";
export const DELETE_EVENT = "@event/DELETE_EVENT";
export const GET_ALL_ATTENDANCE = "@event/GET_ALL_ATTENDANCE";
export const ATTEND_USER = "@event/ATTEND_USER";

// action - video reducer
export const RESET_VIDEO_STORE = "@video/RESET_VIDEO_STORE";
export const GET_ALL_VIDEOS = "@video/GET_ALL_VIDEOS";
export const CREATE_VIDEO = "@video/CREATE_VIDEO";
export const UPDATE_VIDEO = "@video/UPDATE_VIDEO";
export const DELETE_VIDEO = "@video/DELETE_VIDEO";

// action - pdf reducer
export const RESET_PDF_STORE = "@pdf/RESET_PDF_STORE";
export const GET_ALL_PDF = "@pdf/GET_ALL_PDF";
export const CREATE_PDF = "@pdf/CREATE_PDF";
export const UPDATE_PDF = "@pdf/UPDATE_PDF";
export const DELETE_PDF = "@pdf/DELETE_PDF";

// action - user reducer
export const RESET_USER_STORE = "@user/RESET_USER_STORE";
export const GET_ALL_USER = "@user/GET_ALL_USER";
export const GET_SUBSCRIBED_COURSES = "@user/GET_SUBSCRIBED_COURSES";
export const UPDATE_SUBSCRIBED_USER = "@user/UPDATE_SUBSCRIBED_USER";
export const ADD_NEW_SUBSCRIBED_USER = "@user/ADD_NEW_SUBSCRIBED_USER";
export const GET_OPEN_COURSES = "@user/GET_OPEN_COURSES";
export const GET_PAYMENT_URL = "@user/GET_PAYMENT_URL";
export const GET_USER_PROFILE = "@user/GET_USER_PROFILE";
export const UPDATE_USER_PROFILE = "@user/UPDATE_USER_PROFILE";
export const GET_USER_VIDEOS = "@user/GET_USER_VIDEOS";
export const GET_USER_PDFS = "@user/GET_USER_PDFS";
export const GET_USER_MEETINGS = "@user/GET_USER_MEETINGS";
export const GET_USER_MEETING_LINK = "@user/GET_USER_MEETING_LINK";
export const REGISTRATION_REQUIRE_CHECK = "@user/REGISTRATION_REQUIRE_CHECK";
export const EXPORT_SUBSCRIBED_USER_LIST = "@user/EXPORT_SUBSCRIBED_USER_LIST";

// action - settings reducer
export const RESET_SETTINGS_STORE = "@settings/RESET_SETTINGS_STORE";
export const GET_SETTINGS_INFO = "@settings/GET_SETTINGS_INFO";
export const UPDATE_SETTINGS_INFO = "@settings/UPDATE_SETTINGS_INFO";

// action - reports reducer
export const RESET_REPORTS_STORE = "@reports/RESET_SETTINGS_STORE";
export const GET_GST_REPORT = "@reports/GET_GST_REPORT";
export const GET_ORDER_GUEST_REPORT = "@reports/GET_ORDER_GUEST_REPORT";
export const GET_INTERNATIONAL_MAILING_REPORT =
  "@reports/GET_INTERNATIONAL_MAILING_REPORT";
export const GET_DOMESTIC_LODGING_REPORT =
  "@reports/GET_DOMESTIC_LODGING_REPORT";

// action - reports reducer
export const RESET_CERTIFICATE_STORE = "@certificate/RESET_CERTIFICATE_STORE";
export const GET_CERTIFICATE_DATA = "@certificate/GET_CERTIFICATE_DATA";
export const SEND_CERTIFICATE_EMAIL = "@certificate/SEND_CERTIFICATE_EMAIL";
