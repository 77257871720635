import { fetchData } from "axios/axiosUtils";

export async function sendCertificateEmail(data, params) {
    const url = `/api/subscriptions/certificate/${data}`;
    return fetchData(url, params);
}

export async function getCertificateData(data, params) {
    const url = `/certificate/${data}`;
    return fetchData(url, params);
}

