import { useEffect, useMemo, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import config from 'config';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const userInfo = useSelector(state => state.authorization.userInfo); 
    const [userRole, setuserRole] = useState('user')
    useEffect(() => {
        setuserRole(userInfo?.role || 'user')
    }, [userInfo])
    
    const MemoizedMainRoutes = useMemo(() => MainRoutes(userRole), [userRole]) 

    return useRoutes([MemoizedMainRoutes, AuthenticationRoutes], config.basename);
}
