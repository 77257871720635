// assets
import { IconKey, IconSchool, IconDashboard } from "@tabler/icons";
// import getRegisteredCourses from "services/side-menu/getRegisteredCourses";

// constant
const icons = {
  IconKey,
  IconSchool,
  IconDashboard,
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

// const showCourseItems = () => {
//   const list = getRegisteredCourses();
//   if (list.length > 0) {
//     return list;
//   } else {
//     return [
//         {
//             id: 'buyCourse',
//             title: 'Subscribe to a course',
//             type: 'item',
//             url: '/register-course',
//             breadcrumbs: false
//         }
//     ];
//   }
// };

const pages = {
  id: "pages",
  title: "My List",
  caption: "Register to a course to view course content",
  type: "group",
  children: [
    {
      id: "Dashboard",
      title: "Dashboard",
      type: "item",
      url: "/",
      icon: icons.IconDashboard,
      breadcrumbs: false,
    },
    // {
    //   id: "courses",
    //   title: "Courses",
    //   type: "collapse",
    //   icon: icons.IconSchool,
    //   breadcrumbs: false,
    //   children: showCourseItems(),
    // },
  ],
};

export default pages;
