import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import NotFoundPage from "ui-component/utils/NotFoundPage";
import RedirectionCheck from "views/pages/user/redirection/RedirectionCheck";

// user routing
const UserDashboard = Loadable(lazy(() => import("views/pages/user/home")));
const MyProfile = Loadable(lazy(() => import("views/pages/user/profile/MyProfile")));
const PdfViewer = Loadable(lazy(() => import("ui-component/molecules/PdfViewer")));
const RegisterUser = Loadable(lazy(() => import("views/pages/user/registration/RegisterUser")));
const SubscriptionSuccess = Loadable(lazy(() => import("views/pages/user/registration/SubscriptionSuccess")));
const SubscriptionFailed = Loadable(lazy(() => import("views/pages/user/registration/SubscriptionFailed")));

// dashboard routing
const AdminDashboard = Loadable(
  lazy(() => import("views/admin/home/AdminDashboard"))
);

const ManageCourse = Loadable(lazy(() => import("views/admin/manageCourse")));
const CreateCourse = Loadable(
  lazy(() => import("views/admin/manageCourse/update/CreateCourse"))
);
const EditCourse = Loadable(
  lazy(() => import("views/admin/manageCourse/update/EditCourse"))
);
const ManageCourseSubscribers = Loadable(
  lazy(() => import("views/admin/manageCourse/list/CourseUsers"))
);

const ManageEvent = Loadable(lazy(() => import("views/admin/manageEvents")));
const CreateEvent = Loadable(
  lazy(() => import("views/admin/manageEvents/update/CreateEvent"))
);
const EditEvent = Loadable(
  lazy(() => import("views/admin/manageEvents/update/EditEvent"))
);
const AttendanceList = Loadable(
  lazy(() => import("views/admin/manageEvents/list/AttendanceList"))
);
const ManuallyAttendUser = Loadable(
  lazy(() => import("views/admin/manageEvents/update/ManuallyAttendUser"))
);

const ManageVideo = Loadable(lazy(() => import("views/admin/manageVideos")));
const CreateVideo = Loadable(
  lazy(() => import("views/admin/manageVideos/create/CreateVideo"))
);

const ManagePdf = Loadable(lazy(() => import("views/admin/managePDF")));
const CreatePdf = Loadable(
  lazy(() => import("views/admin/managePDF/create/CreatePdf"))
);

const ManageUser = Loadable(lazy(() => import("views/admin/manageUser")));

const ManageReports = Loadable(lazy(() => import("views/admin/manageReport")));


// utilities routing
const DashboardDefault = Loadable(lazy(() => import("views/admin/default")));
const UtilsTypography = Loadable(
  lazy(() => import("views/utilities/Typography"))
);
const UtilsColor = Loadable(lazy(() => import("views/utilities/Color")));
const UtilsShadow = Loadable(lazy(() => import("views/utilities/Shadow")));
const UtilsMaterialIcons = Loadable(
  lazy(() => import("views/utilities/MaterialIcons"))
);
const UtilsTablerIcons = Loadable(
  lazy(() => import("views/utilities/TablerIcons"))
);

// sample page routing
const SamplePage = Loadable(lazy(() => import("views/sample-page")));
const Documentation = Loadable(lazy(() => import("views/documentation")));

// ==============================|| MAIN ROUTING ||============================== //

const ProtectedRoutes = (ChildComp, userInfo) => {
  if (userInfo === "admin") {
    return <ChildComp />;
  } else {
    return <NotFoundPage label="Sorry you don't have access to this page!" />;
  }
};

const MainRoutes = (userInfo) => {
  return {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <UserDashboard />,
      },
      {
        path: "/redirection-check",
        element: <RedirectionCheck />,
      },
      {
        path: "/pdf-viewer/:url",
        element: <PdfViewer />,
      },
      {
        path: "/payment",
        element: <RegisterUser />,
      },
      {
        path: "/payment/confirm/success/:id",
        element: <SubscriptionSuccess />,
      },
      {
        path: "/payment/confirm/cancel/:id",
        element: <SubscriptionFailed />,
      },
      {
        path: "/admin",
        element: ProtectedRoutes(AdminDashboard, userInfo),
      },
      {
        path: "/admin/manage-reports",
        element: ProtectedRoutes(ManageReports, userInfo),
      },
      {
        path: "/admin/manage-user",
        element: ProtectedRoutes(ManageUser, userInfo),
      },
      {
        path: "/admin/manage-course",
        element: ProtectedRoutes(ManageCourse, userInfo),
      },
      {
        path: "/admin/manage-course/create",
        element: ProtectedRoutes(CreateCourse, userInfo),
      },
      {
        path: "/admin/manage-course/edit/:id",
        element: ProtectedRoutes(EditCourse, userInfo),
      },
      {
        path: "/admin/manage-course/subscriber/:id",
        element: ProtectedRoutes(ManageCourseSubscribers, userInfo),
      },
      {
        path: "/admin/manage-event",
        element: ProtectedRoutes(ManageEvent, userInfo),
      },
      {
        path: "/admin/manage-event/create",
        element: ProtectedRoutes(CreateEvent, userInfo),
      },
      {
        path: "/admin/manage-event/edit/:id",
        element: ProtectedRoutes(EditEvent, userInfo),
      },
      {
        path: "/admin/manage-event/attendance/:id",
        element: ProtectedRoutes(AttendanceList, userInfo),
      },
      {
        path: "/admin/manage-event/attendance/:courseId/:eventId/:userId",
        element: ProtectedRoutes(ManuallyAttendUser, userInfo),
      },
      {
        path: "/admin/manage-video",
        element: ProtectedRoutes(ManageVideo, userInfo),
      },
      {
        path: "/admin/manage-video/create",
        element: ProtectedRoutes(CreateVideo, userInfo),
      },
      {
        path: "/admin/manage-pdf",
        element: ProtectedRoutes(ManagePdf, userInfo),
      },
      {
        path: "/admin/manage-pdf/create",
        element: ProtectedRoutes(CreatePdf, userInfo),
      },
      {
        path: "/dashboard/default",
        element: ProtectedRoutes(DashboardDefault, userInfo),
      },
      {
        path: "/utils/util-typography",
        element: ProtectedRoutes(UtilsTypography, userInfo),
      },
      {
        path: "/utils/util-color",
        element: ProtectedRoutes(UtilsColor, userInfo),
      },
      {
        path: "/utils/util-shadow",
        element: ProtectedRoutes(UtilsShadow, userInfo),
      },
      {
        path: "/icons/tabler-icons",
        element: ProtectedRoutes(UtilsTablerIcons, userInfo),
      },
      {
        path: "/icons/material-icons",
        element: ProtectedRoutes(UtilsMaterialIcons, userInfo),
      },
      {
        path: "/user/my-profile",
        element: <MyProfile />,
      },
      {
        path: "/sample-page",
        element: <SamplePage />,
      },
      {
        path: "/documentation",
        element: <Documentation />,
      },
      {
        path: "/*",
        element: <NotFoundPage label="404 - Page Not Found!" />,
      },
    ],
  };
};

export default MainRoutes;
