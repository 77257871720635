// action - state management
import * as actionTypes from "../actions";

export const initialState = {
  pdfList: [],
  loading: false,
  updateError: false,
  getError: false,
  success: false,
  message: null,
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const pdfReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_PDF:
      return {
        ...state,
        success: action?.payload?.success || false,
        message: action?.payload?.message || "",
        loading: action?.payload?.loading || false,
        updateError: action?.payload?.error || false,
      };
    case actionTypes.GET_ALL_PDF:
      return {
        ...state,
        message: action?.payload?.message || "",
        loading: action?.payload?.loading || false,
        getError: action?.payload?.error || false,
        pdfList: action?.payload?.pdfList || [],
      };
    case actionTypes.DELETE_PDF:
      return {
        ...state,
        message: action?.payload?.message || "",
        loading: action?.payload?.loading || false,
        getError: action?.payload?.error || false,
      };
    case actionTypes.RESET_PDF_STORE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default pdfReducer;
