// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { useSelector } from 'react-redux';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { userInfo } = useSelector((state) => state.authorization);
    
    const navItems = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                if(item?.privateRoute) {
                    if(userInfo?.role === "admin") {
                        return <NavGroup key={item.id} item={item} />
                    } else {
                        return null
                    }
                } else {
                    return <NavGroup key={item.id} item={item} />
                }
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
