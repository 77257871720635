import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router"
import {
  REGISTRATION_REQUIRE_CHECK,
  RESET_SNACKBAR,
  SET_SNACKBAR,
} from "store/actions"
import { severity } from "store/customization/constant"
import { getMailingInfo } from "store/settings/settingsMiddleware"
import {
  checkOpenSubscription,
  checkUserSubscription,
} from "store/user/userMiddleware"
import Loader from "ui-component/Loader"

export default function RedirectionCheck() {
  const {
    loading,
    getError,
    message,
    subCheckLoading,
    openCourseLoading,
    loadingForRegistrationCheck,
    registrationRequiredCourses,
  } = useSelector((state) => state.user)
  const { loading: settingLoading, settingsItems } = useSelector(
    (state) => state.settings
  )
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getMailingInfo())
    dispatch(checkUserSubscription({}))
    dispatch(checkOpenSubscription({ type: "opening_soon" }))
    return () => {
      dispatch({ type: RESET_SNACKBAR })
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!openCourseLoading && !subCheckLoading) {
      dispatch({ type: REGISTRATION_REQUIRE_CHECK })
    }
    if (getError) {
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          isOpenSnackbar: true,
          severity: severity.error,
          message: message || "Course fetch failed!",
        },
      })
      navigate()
    }
    // eslint-disable-next-line
  }, [loading, dispatch, getError, message, openCourseLoading, subCheckLoading])

  useEffect(() => {
    if (!loadingForRegistrationCheck && !settingLoading) {
      console.log(
        "test registrationRequiredCourses.length > 0 settingsItems?.is_registration",
        registrationRequiredCourses.length > 0,
        settingsItems?.is_registration
      )
      if (
        registrationRequiredCourses.length > 0 &&
        settingsItems?.is_registration
      ) {
        navigate("/payment")
      } else {
        navigate("/")
      }
    }
    // eslint-disable-next-line
  }, [loadingForRegistrationCheck, settingLoading, settingsItems])

  return <Loader />
}
