// action - state management
import * as actionTypes from "../actions";

export const initialState = {
  certificateData: [],
  loading: false,
  updateError: false,
  getError: false,
  success: false,
  message: null,
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const certificateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CERTIFICATE_DATA:
      return {
        ...state,
        message: action?.payload?.message || "",
        loading: action?.payload?.loading || false,
        getError: action?.payload?.error || false,
        certificateData: action?.payload?.data || [],
      };
    case actionTypes.RESET_CERTIFICATE_STORE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default certificateReducer;
