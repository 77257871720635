// eslint-disable-next-line no-unused-vars
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { clearLocalStorage, getStorageValue } from "services/localStorageService";
const instance = axios.create();
export const baseUrl = 'https://api.basic-neurology.com'
const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
    config.url = `${baseUrl}${config.url}`;
    config.headers.Authorization = `Bearer ${getStorageValue('token', null)}`
    return config;
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
}

const onResponse = (response: AxiosResponse): AxiosResponse => {
    return response;
}

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
    if (error.response?.data?.message === 'Invalid token.') {
        clearLocalStorage("userInfo");
        clearLocalStorage("token");
        window.location.reload()
    }
    return Promise.reject(error);
}

export function getAxiosInstance(): AxiosInstance {
    instance.interceptors.request.use(onRequest, onRequestError);
    instance.interceptors.response.use(onResponse, onResponseError);
    return instance;
}
