// action - state management
import * as actionTypes from "./../actions";

export const initialState = {
  eventList: [],
  attendList: [],
  eventItem: {},
  loading: false,
  updateError: false,
  getError: false,
  success: false,
  message: null,
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_EVENT:
      return {
        ...state,
        success: action?.payload?.success || false,
        message: action?.payload?.message || "",
        loading: action?.payload?.loading || false,
        updateError: action?.payload?.error || false,
      };
    case actionTypes.UPDATE_EVENT:
      return {
        ...state,
        success: action?.payload?.success || false,
        message: action?.payload?.message || "",
        loading: action?.payload?.loading || false,
        updateError: action?.payload?.error || false,
        eventItem: action?.payload?.data || {}
      };
    case actionTypes.GET_ALL_EVENTS:
      return {
        ...state,
        message: action?.payload?.message || "",
        loading: action?.payload?.loading || false,
        getError: action?.payload?.error || false,
        eventList: action?.payload?.eventList || [],
      };
    case actionTypes.GET_EVENT_BY_ID:
      return {
        ...state,
        message: action?.payload?.message || "",
        loading: action?.payload?.loading || false,
        getError: action?.payload?.error || false,
        eventItem: action?.payload?.data || {},
      };
    case actionTypes.DELETE_EVENT:
      return {
        ...state,
        message: action?.payload?.message || "",
        loading: action?.payload?.loading || false,
        getError: action?.payload?.error || false,
      };
    case actionTypes.GET_ALL_ATTENDANCE:
      return {
        ...state,
        message: action?.payload?.message || "",
        loading: action?.payload?.loading || false,
        getError: action?.payload?.error || false,
        attendList: action?.payload?.attendList || [],
      };
    case actionTypes.ATTEND_USER:
      return {
        ...state,
        success: action?.payload?.success || false,
        message: action?.payload?.message || "",
        loading: action?.payload?.loading || false,
        updateError: action?.payload?.error || false
      };
    case actionTypes.RESET_EVENT_STORE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default eventReducer;
