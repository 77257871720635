import {
  getSettingsInfo,
  updateRegistrationSettings,
  updateSettingsInfo,
} from "axios/settings/settingsApi"
import { GET_SETTINGS_INFO, UPDATE_SETTINGS_INFO } from "store/actions"

const dataFetcher = (api, dispatch) => {
  api()
    .then((res) => {
      dispatch({
        type: GET_SETTINGS_INFO,
        payload: { message: null, loading: true, error: false },
      })
      dispatch({
        type: GET_SETTINGS_INFO,
        payload: {
          message: null,
          loading: false,
          settingsItems: res?.data,
          error: false,
        },
      })
    })
    .catch((err) => {
      dispatch({
        type: GET_SETTINGS_INFO,
        payload: {
          error: true,
          message: err.response?.data?.message || "Something went wrong!",
          loading: false,
        },
      })
    })
}

const dataUpdater = (api, data, dispatch, postCB) => {
  dispatch({
    type: UPDATE_SETTINGS_INFO,
    payload: { success: false, message: null, loading: true, error: false },
  })
  api(data)
    .then(() => {
      dispatch({
        type: UPDATE_SETTINGS_INFO,
        payload: {
          success: true,
          message: "Settings updated successfully!",
          loading: false,
          error: false,
        },
      })
      dispatch(postCB())
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_SETTINGS_INFO,
        payload: {
          success: false,
          message: err.response?.data?.message || "Something went wrong!",
          loading: false,
          error: true,
        },
      })
    })
}

export const getMailingInfo = () => {
  return (dispatch) => {
    dataFetcher(getSettingsInfo, dispatch)
  }
}

export const postRegistrationSettings = (data) => {
  return (dispatch) => {
    dataUpdater(updateRegistrationSettings, data, dispatch, getMailingInfo)
  }
}

export const updateMailingInfo = (data) => {
  return (dispatch) => {
    dataUpdater(updateSettingsInfo, data, dispatch, getMailingInfo)
  }
}
