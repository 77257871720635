/* eslint-disable no-undef */
import { getStorageValue } from "services/localStorageService";

export const baseUrl = "https://api.basic-neurology.com";

export async function postFormData(url, body) {
  return fetch(baseUrl + url, {
    method: "post",
    body: body,
    headers: {
      Authorization: `Bearer ${getStorageValue("token", null)}`,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => {
      if (!res.ok) {
        return Promise.reject(res);
      }
      return res;
    })
    .catch((err) => {
      console.log("first error", err);
      return Promise.reject(err);
    });
}
