import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

// project imports
// import Customization from '../Customization';
import { CHECK_USER_STATUS } from 'store/actions';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    // User Status
    const { token, isNewUser } = useSelector(state => state.authorization);

    useEffect(() => {
        dispatch({ type: CHECK_USER_STATUS });
        if (token) {
            if (isNewUser) {
                navigate('payment')
            } else {
                if (!location.pathname.includes('/certificate/'))
                    navigate('/redirection-check')
            }
        }
        // eslint-disable-next-line
    }, [token, isNewUser]);

    return (
        <>
            <Outlet />
            {/* <Customization /> */}
        </>
    )
};

export default MinimalLayout;
